// i18next-extract-mark-ns-start contact-section

import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Hero from 'src/components/sections/hero'

const ContactSection: FC = () => {
  const { t } = useTranslation('contact-section')

  return (
    <Hero
      isH2
      translationContent={{
        subtitle: t('subtitle'),
        firstPartOfTitle: t('first-part-of-title'),
        secondPartOfTitle: t('second-part-of-title'),
        content: t('content'),
        ctaLink: '/contact',
        ctaText: t('button-text'),
        sectionName: t('section-name'),
      }}
      isFullHeight={false}
    />
  )
}

export default ContactSection
