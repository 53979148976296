import { gsap, ScrollTrigger } from 'gsap/all'
import { useEffect, useRef, MutableRefObject } from 'react'
import { useIsLargeScreen } from 'src/helpers/layout'
import { createShowFromBottomAnimation } from 'src/helpers/animations'

gsap.registerPlugin(ScrollTrigger)

export const useHeroAnimation = (
  subtitleRef: MutableRefObject<HTMLHeadingElement>,
  pageTitleRef: MutableRefObject<HTMLHeadingElement>,
  contentRef: MutableRefObject<HTMLParagraphElement>,
  buttonWrapperRef: MutableRefObject<HTMLDivElement>,
  badgesRef: MutableRefObject<HTMLDivElement>
) => {
  const timeline = useRef(gsap.timeline())
  const isLarge = useIsLargeScreen()

  const animations = () => {
    createShowFromBottomAnimation(timeline.current, subtitleRef.current)
    createShowFromBottomAnimation(
      timeline.current,
      pageTitleRef.current,
      subtitleRef.current
    )
    createShowFromBottomAnimation(
      timeline.current,
      contentRef.current,
      subtitleRef.current
    )
    createShowFromBottomAnimation(
      timeline.current,
      buttonWrapperRef.current,
      subtitleRef.current
    )

    if (badgesRef.current) {
      createShowFromBottomAnimation(
        timeline.current,
        badgesRef.current,
        subtitleRef.current
      )
    }

    ScrollTrigger.sort()
  }

  useEffect(() => {
    if (isLarge === null) {
      return
    }

    animations()
  }, [isLarge])
}

export default {}
