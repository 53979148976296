import React, { FC, useRef } from 'react'
import Button from 'src/components/ui/Button'
import Subtitle from 'src/components/ui/Subtitle'
import { Link } from 'gatsby-plugin-react-i18next'
import H1 from 'src/components/ui/title/H1'
import H2 from 'src/components/ui/title/H2'
import { useHeroAnimation } from 'src/components/sections/hero/hero-animations'
import IsoBadge from 'src/components/ui/IsoBadge'
import styled from 'styled-components'
import tw from 'twin.macro'

const StyledSection = styled.section`
  &.full-height {
    padding-top: 100px;
    padding-bottom: 40px;

    @media (min-height: 580px) and (max-width: 767px) {
      padding-top: 110px;
      padding-bottom: 50px;
    }
    @media (min-height: 680px) and (max-width: 767px) {
      padding-top: 160px;
      padding-bottom: 100px;
    }
    @media (min-height: 780px) and (max-width: 767px) {
      padding-top: 210px;
      padding-bottom: 150px;
    }
    @media (min-height: 880px) and (max-width: 767px) {
      padding-top: 260px;
      padding-bottom: 200px;
    }
  }
`

const StyledWidgetsContainer = styled.div`
  ${tw`-bottom-32`}
  @media (min-height: 1000px) {
    ${tw`-bottom-64`}
  }
`

export interface IHeroSectionProps {
  translationContent: {
    subtitle: string
    firstPartOfTitle: string
    secondPartOfTitle: string
    content: string
    ctaLink?: string
    ctaText: string
    ctaLink2?: string
    ctaText2?: string
    sectionName: string
  }
  isH2?: boolean
  showBadges?: boolean
  onButtonClick?: () => void
  isFullHeight?: boolean
}

const HeroSection: FC<IHeroSectionProps> = ({
  translationContent: {
    subtitle,
    firstPartOfTitle,
    secondPartOfTitle,
    content,
    ctaLink,
    ctaText,
    ctaLink2,
    ctaText2,
    sectionName,
  },
  showBadges,
  isH2,
  onButtonClick,
  isFullHeight,
}) => {
  const subtitleRef = useRef<HTMLInputElement>(null)
  const pageTitleRef = useRef<HTMLInputElement>(null)
  const contentRef = useRef<HTMLInputElement>(null)
  const buttonWrapperRef = useRef<HTMLInputElement>(null)
  const badgesRef = useRef<HTMLDivElement>(null)

  useHeroAnimation(
    subtitleRef,
    pageTitleRef,
    contentRef,
    buttonWrapperRef,
    badgesRef
  )

  const getTitle = () => {
    if (isH2) {
      return (
        <H2
          ref={pageTitleRef}
          className="w-full opacity-0"
          firstPart={firstPartOfTitle}
          secondPart={secondPartOfTitle}
        />
      )
    }

    return (
      <H1
        ref={pageTitleRef}
        className="w-full opacity-0"
        firstPart={firstPartOfTitle}
        secondPart={secondPartOfTitle}
      />
    )
  }

  const getButton = () => {
    if (onButtonClick) {
      return <Button onClick={onButtonClick}>{ctaText}</Button>
    }

    if (ctaLink[0] === '#') {
      return (
        <a href={ctaLink}>
          <Button>{ctaText}</Button>
        </a>
      )
    }

    return (
      <Link to={ctaLink}>
        <Button>{ctaText}</Button>
      </Link>
    )
  }

  const getSecondButton = () => {
    if (!ctaLink2) {
      return <></>
    }

    return (
      <a href={ctaLink2} className="ml-4">
        <Button isOutline className="">
          {ctaText2}
        </Button>
      </a>
    )
  }

  return (
    <StyledSection
      data-name={sectionName}
      id={sectionName}
      className={`container flex flex-column relative  ${
        isFullHeight ? 'md:min-h-screen full-height' : 'py-10 md:py-20'
      }`}
    >
      <div className="text-center flex justify-center items-center w-full flex-1 flex-wrap">
        <div
          className={`flex justify-center flex-wrap relative ${
            isH2 ? '' : 'md:mt-0'
          }`}
        >
          <Subtitle ref={subtitleRef} className="opacity-0 mb-2 md:mb-6 w-full">
            {subtitle}
          </Subtitle>
          {getTitle()}
          <p
            ref={contentRef}
            className="opacity-0 mt-8 md:mt-12 max-w-2xl w-full whitespace-pre-line text-sm md:text-base leading-20px md:leading-normal font-semibold"
          >
            {content}
          </p>
          <div
            ref={buttonWrapperRef}
            className="opacity-0 mt-8 md:mt-12 w-full mb-6 md:mb-0"
          >
            <div className="flex justify-center">
              {getButton()}
              {getSecondButton()}
            </div>
          </div>
          {showBadges && (
            <StyledWidgetsContainer
              ref={badgesRef}
              className="opacity-0 w-full md:absolute pointer-events-none	"
            >
              <div className="flex items-center justify-between w-full">
                <div>
                  <iframe
                    src="https://widget.clutch.co//widgets/get/1?ref_domain=localhost&uid=1508300&ref_path=/"
                    height="45px"
                    width="140px"
                    className="border-none block"
                    title="Me & My Friends Clutch Review Widget 1"
                  />
                </div>
                <IsoBadge className="w-28 md:w-32 text-sm sm:text-base self-end" />
              </div>
            </StyledWidgetsContainer>
          )}
        </div>
      </div>
    </StyledSection>
  )
}

HeroSection.defaultProps = {
  showBadges: false,
  onButtonClick: null,
  isFullHeight: true,
  isH2: false,
}

export default HeroSection
