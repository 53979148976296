import React, { forwardRef, useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { I18nextContext, Link } from 'gatsby-plugin-react-i18next'
import { directusFileUrl } from 'src/helpers/directus'

interface IsoBadgeProps {
  className?: string
}

const IsoBadge = forwardRef<HTMLDivElement, IsoBadgeProps>(
  ({ className }, ref) => {
    const data = useStaticQuery(graphql`
      query {
        directus {
          iso_badge {
            translations {
              languages_code {
                code
              }
              caption
              alt
              image {
                id
              }
            }
          }
        }
      }
    `)

    const { language } = useContext(I18nextContext)
    const { image, alt, caption } = data.directus.iso_badge.translations.find(
      (translation) => translation.languages_code.code === language
    )

    return (
      <div
        ref={ref}
        className={`flex flex-col justify-center items-center ${className}`}
      >
        <Link to="/iso">
          <img
            src={directusFileUrl(image.id)}
            alt={alt}
            className="w-full px-4"
          />
        </Link>
        <div className="font-palanquin font-semibold mt-4 whitespace-pre flex justify-center">
          <Link to="/iso">{caption}</Link>
        </div>
      </div>
    )
  }
)

IsoBadge.defaultProps = {
  className: '',
}

export default IsoBadge
