import React, { forwardRef } from 'react'
import emphasizeAmpersand from 'src/helpers/emphasizeAmpersand'

interface IPageTitleProps {
  firstPart: string
  secondPart: string
  secondPartClassName?: string
  fontClassName?: string
  className?: string
}

const H1 = forwardRef<HTMLHeadingElement, IPageTitleProps>(
  ({ firstPart, secondPart, className }, ref) => (
    <h1
      ref={ref}
      className={`font-palanquinDark text-2xl md:text-6xl md:leading-72px ${className}`}
    >
      <span className="block text-black">
        {emphasizeAmpersand(firstPart, 1.1)}
      </span>
      <span className="block text-red">
        {emphasizeAmpersand(secondPart, 1.1)}
      </span>
    </h1>
  )
)

H1.defaultProps = {
  className: '',
}

export default H1
