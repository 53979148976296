import React, { forwardRef } from 'react'
import emphasizeAmpersand from 'src/helpers/emphasizeAmpersand'

interface IPageTitleProps {
  firstPart: string
  secondPart: string
  className?: string
  secondPartRed?: boolean
}

const H2 = forwardRef<HTMLHeadingElement, IPageTitleProps>(
  ({ firstPart, secondPart, className, secondPartRed }, ref) => (
    <h2
      ref={ref}
      className={`font-palanquinDark text-2xl md:text-5xl md:leading-64px ${className}`}
    >
      <span className="block text-black">
        {emphasizeAmpersand(firstPart, 1.1)}
      </span>
      <span className={`block ${secondPartRed ? 'text-red' : 'text-black'}`}>
        {emphasizeAmpersand(secondPart, 1.1)}
      </span>
    </h2>
  )
)

H2.defaultProps = {
  className: '',
  secondPartRed: true,
}

export default H2
