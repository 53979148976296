import { gsap, ScrollTrigger } from 'gsap/all'
import { isMdScreen } from 'src/helpers/layout'

gsap.registerPlugin(ScrollTrigger)

const SINGLE_ANIMATION_TIME_DESKTOP = 0.5
const SINGLE_ANIMATION_TIME_MOBILE = 0.35
const TIME_BETWEEN_ANIMATIONS_TRIGGERS_DESKTOP = 0.2
const TIME_BETWEEN_ANIMATIONS_TRIGGERS_MOBILE = 0.2

const animationsOverlapDesktop =
  SINGLE_ANIMATION_TIME_DESKTOP - TIME_BETWEEN_ANIMATIONS_TRIGGERS_DESKTOP

const animationsOverlapMobile =
  SINGLE_ANIMATION_TIME_MOBILE - TIME_BETWEEN_ANIMATIONS_TRIGGERS_MOBILE

export const getAnimationTriggerPoint = () => {
  if (isMdScreen()) {
    return '150px bottom'
  }

  return '20px bottom'
}

export const getAnimationStepInSeconds = (timeline) => {
  const timeLeftOfCurrentAnimation = timeline.duration() - timeline.time()

  if (isMdScreen() && timeLeftOfCurrentAnimation > animationsOverlapDesktop) {
    return `-=${animationsOverlapDesktop}`
  }

  if (!isMdScreen() && timeLeftOfCurrentAnimation > animationsOverlapMobile) {
    return `-=${animationsOverlapMobile}`
  }

  return null
}

export const getAnimationDurationInSeconds = () => {
  if (isMdScreen()) {
    return SINGLE_ANIMATION_TIME_DESKTOP
  }

  return SINGLE_ANIMATION_TIME_MOBILE
}

export const createShowFromBottomAnimation = (
  timeline,
  element: Element,
  triggerElement?: Element
) => {
  gsap.set(element, {
    y: 20,
  })

  ScrollTrigger.create({
    trigger: triggerElement || element,
    start: getAnimationTriggerPoint(),
    onEnter: () => {
      timeline.to(
        element,
        {
          y: 0,
          opacity: 1,
          duration: getAnimationDurationInSeconds(),
        },
        getAnimationStepInSeconds(timeline)
      )
    },
  })
}

export default {}
