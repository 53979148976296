import React, { forwardRef } from 'react'

interface ISubtitleProps {
  children: JSX.Element | string
  className?: string
}

const Subtitle = forwardRef<HTMLHeadingElement, ISubtitleProps>(
  ({ children, className }, ref) => (
    <h4
      ref={ref}
      style={{ letterSpacing: '3px' }}
      className={`text-red text-sm md:text-base tracking-widest font-semibold font-palanquinDark uppercase leading-24px md:leading-normal ${className}`}
    >
      {children}
    </h4>
  )
)

Subtitle.defaultProps = {
  className: '',
}

export default Subtitle
